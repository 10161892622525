<template>
  <transition
    @leave="leave"
  >
  <!-- v-if="!load" -->
    <Loading
      v-if="!load"
      :overflow="true"
      :load-bg="currentLoadingBg"
    ></Loading>
  </transition>

  <!-- Main content -->
  <div class="container-fluid mt-nav mb-7">
    <div>
      <div class="row">
        <div
          data-aos="fade-up"
          data-aos-delay="1500"
          data-aos-duration="500"
          class="col-12"
        >
          <h1 class="col-12">Virtual exhibition | {{ city[0].City }}</h1>
          <h2 class="h4 col-12 text-uppercase">
            {{ city[0].SchoolName }}
          </h2>
        </div>
        <!-- Virtual Exhibition Details -->
        <div
          data-aos="fade-up"
          data-aos-delay="1900"
          data-aos-duration="500"
          class="col-12 col-lg-7 mt-5"
        >
          <h3 class="text-uppercase">
            {{ city[0].virtual_exhibition.ShortDescription }}
          </h3>
          <div
            v-if="city[0].virtual_exhibition.Description"
            class="en mt-3"
          >
            {{ city[0].virtual_exhibition.Description }}
          </div>
          <div class="en mt-4">
            {{ city[0].virtual_exhibition.Period }}
          </div>
          <span
            class=""
            v-if="city[0].virtual_exhibition.artists"
          >Artist : </span>
          <span v-for="(artist, index) in city[0].virtual_exhibition.artists" :key="artist.Name" class="en">
            <span class="span me-2">
              {{ artist.Name }}
              <span v-if="index !== city[0].virtual_exhibition.artists.length - 1">,</span>
            </span>
          </span>
          <div class="en">
            <span
              class=""
              v-if="city[0].virtual_exhibition.Curator1"
            >Curator : </span>
            <span v-if="city[0].virtual_exhibition.Curator1" class="span me-2">
              {{ city[0].virtual_exhibition.Curator1 }}
            </span>
            <span v-if="city[0].virtual_exhibition.Curator2" class="span me-2">
              {{ city[0].virtual_exhibition.Curator2 }}
            </span>
            <span v-if="city[0].virtual_exhibition.Curator3" class="span me-2">
              {{ city[0].virtual_exhibition.Curator3 }}
            </span>
          </div>
          <a
            :href="city[0].virtual_exhibition.Link"
            target="_blank"
            v-if="city[0].virtual_exhibition.Link"
            class="btn btn-primary mt-3 mt-lg-2"
          >
            <div class="d-flex align-items-center body-regular text-uppercase">
              <span class="h4 mb-0">explore virtual exhhibition</span>
              <img src="../../assets/icon/right-arrow.svg" alt=""
              class="link-icon ms-4 d-none d-lg-block" />
            </div>
          </a>
        </div>
        <!-- Virtual Exhibition Details -->
      </div>
    </div>
  </div>
  <!-- Footer -->
  <Footer
    :links="footerLinks"
    :bottom="scrollToBottom"
  ></Footer>
  <!-- Footer -->
</template>

<script>
import gql from 'graphql-tag';
import gsap from 'gsap';
import AOS from 'aos';
import emitter from '@/components/tools/emitter';
import Loading from '@/components/front/LoadingCity.vue';
import Footer from '@/components/front/Footer.vue';
import 'aos/dist/aos.css';

export default {
  name: 'City',
  data() {
    return {
      slug: '',
      apolloLoad: false,
      currentLoadingBg: 'bg-citya-day',
      city: [
        {
          City: '',
          SchoolName: '',
          virtual_exhibition: {
            Title: '',
            Slug: '',
            ShortDescription: '',
            Description: '',
            Period: '',
            artists: [
              {
                Name: '',
              },
            ],
            Curator1: '',
            Curator2: '',
            Curator3: '',
            Link: '',
          },
        },
      ],
      haveScreening: false,
      detailsOverlay: false,
      SelectedExhibition: {},
      SelectedExhibitionImages: [],
      SelectedExhibitionVideos: [],
      currentCity: '',
      currentHour: null,
      footerLinks: [],
      scrollToBottom: false,
    };
  },
  components: {
    Loading,
    Footer,
  },
  computed: {
    load() {
      console.log(`apollo status: ${this.apolloLoad}`);
      AOS.init({ once: false, disable: 'phone' });
      AOS.refresh();
      return this.apolloLoad;
    },
  },
  watch: {
    city: {
      deep: true,
      handler() {
        emitter.emit('emit-city', this.city[0].City);
        // update footer link
        const linkData1 = {
          City: this.city[0].City,
          Slug: this.slug,
          Text: `BACK TO ${this.city[0].City}`,
          Link: `/city/${this.slug}`,
        };
        const linkData2 = {
          City: this.city[0].City,
          Slug: this.slug,
          Text: `E-XHIBITION | ${this.city[0].City}`,
          Link: `/city/${this.slug}/artists`,
        };
        this.footerLinks.push(linkData1, linkData2);
        if (this.city[0].City === 'Hong Kong') {
          const linkData3 = {
            City: this.city[0].City,
            Slug: this.slug,
            Text: `YOUTH CURRENT | ${this.city[0].City}`,
            Link: `/city/${this.slug}/artists`,
          };
          this.footerLinks.push(linkData3);
        }
      },
    },
  },
  apollo: {
    city: {
      query: gql`
        query VirtualExhibition($Slug: String!) {
          cities(where: {Slug: $Slug}) {
            Slug
            City
            SchoolName
            virtual_exhibition {
              Title
              Slug
              ShortDescription
              Description
              Period
              artists {
                Name
              }
              Curator1
              Curator2
              Curator3
              Link
            }
          }
        }
      `,
      variables() {
        return {
          Slug: this.$route.params.slug,
        };
      },
      update(data) {
        return data.cities;
      },
      error(error) {
        console.error("We've got an error!", error);
      },
      loadingKey: 'loadingQueriesCount',
      // watchLoading will be called whenever the loading state changes
      // eslint-disable-next-line no-unused-vars
      watchLoading(isLoading, countModifier) {
        // isLoading is a boolean
        // countModifier is either 1 or -1

        this.apolloLoad = false;
        if (this.currentLoadingBg !== 'bg-citya-day' && isLoading) {
          this.updateLoadBg();
        }
        // console.log('watch loading', isLoading, countModifier);
        if (!isLoading) {
          this.apolloLoad = true;
          console.log('load complete');
        }
      },
    },
  },
  methods: {
    /**
     * Page Load effects
     */
    updateLoadBg() {
      switch (this.$route.params.slug) {
        case 'hong-kong':
          this.currentLoadingBg = 'bg-hongkong-day';
          break;
        case 'macao':
          this.currentLoadingBg = 'bg-macau-day';
          break;
        case 'beijing':
          this.currentLoadingBg = 'bg-beijing-day';
          break;
        case 'san-francisco':
          this.currentLoadingBg = 'bg-sf-day';
          break;
        case 'tallinn':
          this.currentLoadingBg = 'bg-tallinn-day';
          break;
        case 'rome':
          this.currentLoadingBg = 'bg-rome-day';
          break;
        default:
          this.currentLoadingBg = 'bg-citya-day';
          break;
      }
    },
    removeLoadBg(lastCitySlug) {
      switch (lastCitySlug) {
        case 'hong-kong':
          return 'bg-hongkong-day';
        case 'macao':
          return 'bg-macau-day';
        case 'beijing':
          return 'bg-beijing-day';
        case 'san-francisco':
          return 'bg-sf-day';
        case 'tallinn':
          return 'bg-tallinn-day';
        case 'rome':
          return 'bg-rome-day';
        default:
          return 'bg-citya-day';
      }
    },
    leave(el, done) {
      gsap.to(el, {
        duration: 0.3,
        delay: 1.8,
        opacity: 0,
        ease: 'ease-out',
        onComplete: done,
      });
      setTimeout(() => {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('overflow-hidden');
      }, 2000);
    },
    /**
     * Main methods
     */
    onScroll() {
      const windowY = window.scrollY;
      const intViewportHeight = window.innerHeight;
      if (windowY + intViewportHeight >= document.documentElement.offsetHeight) {
        this.scrollToBottom = true;
      } else {
        this.scrollToBottom = false;
      }
    },
    closeDetailsOverlay() {
      this.detailsOverlay = false;
    },
  },
  mounted() {
    console.clear();
    this.slug = this.$route.params.slug;
    this.updateLoadBg();
    window.addEventListener('scroll', this.onScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.onScroll);
  },
};
</script>
